/* Container for the About section */
.about-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  gap: 30px;
  background-color: #333333;
}

/* Content Section */
.about-content {
  flex: 1;
  max-width: 50%;
  margin-left: 20px; /* Margin on left side of the content in desktop view */
}

.about-heading h2 {
  font-size: 40px; /* Retaining the heading size */
  font-weight: bold;
  margin-bottom: 20px;
  color: #FF914D;
}

.about-content p {
  font-size: 20px; /* Larger font for body text */
  line-height: 1.8;
  color: aliceblue; /* Lighter color for the body text */
}

/* Image section */
.image-card {
  padding: 20px;
  border-radius: 100px; /* Increased border radius */
  background-image: linear-gradient(to left, #444444, #666666); /* Gradient with 3 colors */
  border: 1px solid transparent; /* Reduced border width */
  box-sizing: border-box;
  width: 100%;
  margin-right: 20px; /* Margin on right side of the image in desktop view */
  margin-left: 10px; /* Add some margin to the left side to decrease tightness */
}

.image-card img {
  max-width: 100%;
  height: auto;
  border-radius: 120px; /* Increased border-radius for the image */
  display: block;
  margin-left: auto;
  margin-right: auto; /* Center the image horizontally */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .about-container {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .about-content {
    max-width: 100%;
    text-align: center;
    margin-left: 0; /* Remove margin-left for mobile */
  }

  .about-heading h2 {
    font-size: 30px; /* Heading size adjustment for mobile */
    color: #FF914D;
  }

  .about-content p {
    font-size: 16px; /* Slightly smaller text for mobile */
    margin-top: 15px;
    color: white;
  }

  .image-card {
    width: 90%;
    max-width: 280px; /* Reduced width in mobile view */
    margin-top: 20px;
    text-align: center;
    margin-left: 20px; /* Increased left margin in mobile view to avoid tightness */
  }

  .image-card img {
    margin-left: 0; /* Remove left margin for the image in mobile view */
    margin-right: auto; /* Ensure image is centered */
  }
}

/* Fade-in animation for content */
.fade-in {
  animation: fadeIn 1s forwards;
}

/* Slide-in animation for image */
.slide-in {
  animation: slideIn 1s forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateX(50px);
  }
  100% {
    transform: translateX(0);
  }
}
