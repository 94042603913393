/* Contact Section */
#contact {
  padding: 50px 20px;
  /* background-color: #666666; */
  background: url('./contact.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  box-sizing: border-box;
}

/* Main Heading Styles */
.contact-heading {
  font-size: 36px;
  color: #FF7F32;
  margin-bottom: 30px;
}

/* Contact Wrapper Styles */
.contact-wrapper {
  background-color: transparent; /* Remove background color in both desktop and mobile */
  padding: 30px;
  border-radius: 22px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: center; /* Center the content */
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  gap: 30px;
  box-sizing: border-box;
}

/* Contact Info Styles */
.contact-info {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding-left: 20px;
  text-align: left;
  width: 100%;
}

.contact-info p,
.contact-info .email,
.contact-info .phone,
.contact-info .address {
  font-size: 18px;
  font-weight: bolder;
  margin: 10px 0;
  color: white;
}

/* Contact Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  background-color: 666666; /* Keep background color for the form */
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid #333333; /* Set border color for contact form */
  border-width: 8px;
  border-color: #666666;
}

.contact-form input,
.contact-form textarea {
  width: 90%;
  max-width: 500px; /* Prevent overflow and make sure it stays within the form */
  padding: 12px;
  margin: 10px 0;
  border: 2px solid #333333;
  border-radius: 20px;
  font-size: 16px;
}

.contact-form button {
  padding: 12px 24px;
  background-color: #FF7F32;
  color:white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  margin: 20px 30px;
}

.contact-form button:hover {
  background-color: #FF7F32;
  transform: scale(1.05); /* Grow effect on hover */
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.social-icons a {
  margin: 0 15px;
  color: white;
  text-decoration: none;
}

.social-icons a:hover {
  color: #FF7F32;
}

/* Mobile Styles */
@media (max-width: 768px) {
  /* Stack contact info and contact form vertically */
  .contact-wrapper {
    flex-direction: column;
    background-color: transparent; /* Remove background color for mobile */
    box-shadow: none;
    padding: 0;
    gap: 20px; /* Add spacing between stacked items */
    align-items: center; /* Center align the boxes */
  }

  /* Contact Info Styles for Mobile */
  .contact-info {
    width: 100%;
    max-width: 500px;
    text-align: center;
    background-color: transparent; /* Remove background color only for contact info */
    box-shadow: none;
    padding: 0 10px; /* Add some side padding for better spacing */
  }

  /* Contact Form: Keep Background */
  .contact-form {
    /* background-color: #333333; */
    padding: 20px;
    border-radius: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 460px; /* Reduce max-width to prevent overflow */
    margin: 0 auto;
    box-sizing: border-box;
    border-width: 6px;
    border-color: #666666;
  }

  /* Reduce max-width of input fields in mobile view */
  .contact-form input,
  .contact-form textarea {
    max-width: 460px; /* Reduce input field width to fit within container */
    border-radius: 15px;
  }

  .contact-info h1 {
    text-align: center;
  }
}

/* Desktop Styles */
@media (min-width: 769px) {
  /* Ensure contact form inputs are centered */
  .contact-form input,
  .contact-form textarea {
    margin-left: auto;
    margin-right: auto;
  }

  /* Align social icons with the contact details in desktop view */
  .contact-info .social-icons {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;
  }

  /* Increase margin between the details box and contact form */
  .contact-wrapper {
    gap: 50px; /* Increased gap */
  }
}
/* Contact Form Fade-in Delay */
.contact-form {
  animation-delay: 0.2s;
}

.contact-info {
  animation-delay: 0.4s;
}
.error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}
a {
  text-decoration: none; /* Removes the underline */
  color: inherit; /* Inherits the color of the parent element */
}
/* Success message styles */
.success-message {
  color: green;
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
}