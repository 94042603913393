/* General Styling */
.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #333333;
  color: #fff;
  padding: 50px 20px;
  font-family: 'Poppins', sans-serif;
  height: 100vh; /* Take full viewport height */
  overflow: hidden; /* Prevent any scrollbars */
}

.contact-header {
  text-align: center;
  margin-bottom: 20px;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #FF7F32;
  margin-bottom: 10px;
}

.contact-subtitle {
  font-size: 1.2rem;
  color: white;
  line-height: 1.5;
  max-width: 600px;
  margin: 0 auto;
}

.calendar-container {
  width: 100%;
  max-width: 1200px;
  margin-top: 20px;
  padding: 0;
  background-color: #333333;
  border-radius: 15px;
  box-shadow: 0 2px 10px rgba(194, 168, 168, 0.945);
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Ensure no scrollbars appear */
}

/* Add padding for small screens */
@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem;
    color: #FF7F32;
  }

  .contact-subtitle {
    font-size: 1rem;
  }

  .calendar-container {
    height: 80vh; /* Adjust height for smaller screens */
    width: 100%;
    padding: 10px; /* Add padding to the container */
  }
}

@media (max-width: 480px) {
  .calendar-container {
    height: 75vh; /* Adjust height further for very small screens */
    width: 100%;
    padding: 10px; /* Add padding for very small screens */
  }

  .footer-text {
    font-size: 20px;
  }
}
