/* Hero Section */
.hero {
  text-align: center;
  padding: 250px 20px; /* Increase the top and bottom padding to make the section taller */
  background-size: cover;
  position: relative;
  color: #FFFFFF;
  overflow: hidden;
  opacity: 0; /* Initially hidden */
  transform: translateY(20px); /* Initially below */
  transition: transform 1s ease-in-out, opacity 1s ease-in-out; /* Smooth transition */
}

.hero.visible {
  opacity: 1; /* Fade in when visible */
  transform: translateY(0); /* Slide to normal position */
}

/* Parallax Effect for Background */
.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: cover;
  background-position: center center;
  will-change: transform; /* Hint to browser to optimize for transformation */
  z-index: -1;
  transition: transform 0.3s ease-out; /* Smooth transition for the parallax effect */
}

.hero.visible::before {
  transform: translateY(10%); /* Slight parallax movement */
}

/* Animating the Title (h1) */
.hero-title {
  font-size: 48px;
  color: #FFFFFF;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.8); /* Shadow for contrast */
  transform: translateX(100%); /* Initially off-screen */
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth transition */
}

.hero-title.animate {
  transform: translateX(0); /* Move into place */
  opacity: 1; /* Fade in */
}

/* Animating the Description (p) */
.hero-desc {
  font-size: 20px;
  margin: 20px 0;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8); /* Subtle shadow for readability */
  transform: translateX(-100%); /* Initially off-screen */
  opacity: 0; /* Initially hidden */
  transition: transform 1s ease-out, opacity 1s ease-out; /* Smooth transition */
}

.hero-desc.animate {
  transform: translateX(0); /* Move into place */
  opacity: 1; /* Fade in */
}

/* Button Styles (Optional) */
.btn {
  padding: 10px 20px;
  background-color: #FF914D;
  color: #2C2C2C;
  border: none;
  cursor: pointer;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 5px;
  transition: background-color 0.3s, transform 0.2s;
}

.btn:hover {
  background-color: #FF7733;
  transform: scale(1.05); /* Slight hover effect */
}

/* Large Section for Desktop */
@media (min-width: 1024px) {
  .hero {
    padding: 400px 50px; /* Further increase padding for larger screens */
    background-size: cover; /* Ensure the background stretches to cover */
    background-position: center center; /* Keep the image centered */
  }
}
