footer {
    text-align: center;
    padding: 20px;
    background-color: #6e6e6e;
  }
  
  footer a {
    color: #FF914D;
    text-decoration: none;
  }
  
  footer a:hover {
    text-decoration: underline;
  }
  