.testimonials-section {
    background-color: #333333;
    color: #ffffff;
    text-align: center;
    padding: 50px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .text-wrap {
    background-color: #444444;
    padding: 20px;
    text-align: left;
    width: 100%;
  }
  
  .section-title {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: white;
  }
  
  .section-header {
    font-size: 46px;
    font-weight: 700;
    margin-bottom: 40px;
    color: #FF7F32;
  }
  
  .testimonials-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .testimonial-card {
    background-color: #666666;
    border-radius: 12px;
    padding: 20px;
    width: 350px; /* Increased width on desktop */
    height: 420px;
    position: relative;
    box-shadow:
      0 10px 20px rgba(0, 0, 0, 0.3),
      0 0 20px rgba(255, 127, 50, 0.5), /* Soft orange shadow */
      0 0 15px rgba(0, 0, 0, 0.6); /* Darker shadow for depth */
    transform: scale(1);
    opacity: 0;
    transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .testimonial-card.visible {
    opacity: 1;
    transform: scale(1.1); /* Increased scale for more visible motion */
  }
  
  .testimonial-card:hover {
    transform: scale(1.15); /* Increased scale on hover for more tilt */
    box-shadow:
      0 25px 40px rgba(0, 0, 0, 0.6), /* Stronger shadow on hover */
      0 0 30px rgba(255, 127, 50, 0.6); /* Enhanced orange shadow */
  }
  
  .testimonial-quote {
    font-size: 16px;
    font-style: italic;
    margin-bottom: 20px;
    text-align: center; /* Centered the quote text */
  }
  
  .testimonial-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  
  .testimonial-avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #8888aa;
    background-color: white;
  }
  
  .testimonial-details {
    text-align: left;
  }
  
  .testimonial-name {
    font-size: 14px;
    font-weight: 600;
    color: #ffffff;
  }
  
  .testimonial-title {
    font-size: 12px;
    color: #bbbbcc;
  }
  
  .quote-symbol {
    position: absolute;
    top: -20px;
    left: 20px;
    font-size: 50px;
    color: #FF7F32;
    font-weight: bold;
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .testimonial-card.visible {
    animation: fadeInUp 0.5s ease-out forwards;
  }
  
  @media (max-width: 768px) {
    .section-title {
      font-size: 36px;
      color: #FF7F32;
    }
  
    .section-header {
      font-size: 40px;
      color: #FF7F32;
    }
  
    .testimonials-container {
      flex-direction: column;
      align-items: center;
    }
  
    .testimonial-card {
      width: 280px; /* Reduced width on mobile */
      height: 350px;
    }
  
    .testimonial-quote {
      font-size: 14px; /* Reduced font size for mobile */
    }
  
    .testimonial-name {
      font-size: 12px; /* Reduced font size for mobile */
    }
  }
  
  @media (max-width: 480px) {
    .testimonials-container {
      padding: 10px;
    }
  }
  