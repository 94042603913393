/* General styles */
.portfolio-container {
  text-align: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.portfolio-title {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.portfolio-subtitle {
  color: #FF7F32;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

/* Category Container */
.category-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Added space between the buttons */
  position: relative;
  flex-wrap: wrap; /* Ensures wrapping on smaller screens */
  margin-top: 20px; /* Added top margin */
  margin-bottom: 20px; /* Added bottom margin */
}

.section-title {
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
}

.section-header {
  font-size: 46px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #FF7F32;
}

/* Category Button */
.category-button {
  background-color: #fff;
  border: 2px solid white;
  color: white;
  padding: 15px 25px;
  cursor: pointer;
  border-radius: 25px;
  font-size: 1.2rem;
  text-transform: capitalize;
  transition: background-color 0.3s, color 0.3s, transform 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px; /* Ensuring margins are applied to all sides */
  background-color: #333333;
  font-weight: bolder;
  border-width: 4px;
}

.category-button:hover {
  background-color: #FF7F32;
  color: #fff;
  transform: scale(1.1);
}

.category-button.active {
  background-color: #FF7F32;
  color: #fff;
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

/* Category Item - Centered and Sleek */
.category-button:focus {
  outline: none;
}

.category-button:active {
  transform: scale(0.98);
}

/* Portfolio slider styles */
.portfolio-slider {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;
}

.portfolio-projects {
  display: flex;
  justify-content: center; /* Center the items */
  transition: transform 0.5s ease-in-out;
  width: calc(100% * 3); /* Adjust based on items visible */
}

.project-card {
  display: flex;
  flex-direction: column; /* Stack image and text vertically */
  flex: 0 0 calc(33.333% - 20px); /* Show 3 projects in desktop view */
  max-width: calc(30.333% - 20px);
  margin: 0 10px;
  text-align: center;
  border: 1px solid #666666;
  border-radius: 30px;
  overflow: hidden;
  box-shadow:
      0 10px 20px rgba(0, 0, 0, 0.3),
      0 0 20px rgba(255, 127, 50, 0.5), /* Soft orange shadow */
      0 0 15px rgba(0, 0, 0, 0.6); /* Darker shadow for depth */
  background-color: #444444;
  transform: scale(1);
  transition: opacity 0.5s ease, transform 0.5s ease, box-shadow 0.3s ease;
  height: 450px; /* Adjust the overall height of the project card */
  border-width: 3px;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* When the user hovers over the project card, elevate it */
.project-card:hover {
  transform: scale(1.05); /* Slight zoom effect for elevation */
  box-shadow: 
      0 15px 30px rgba(0, 0, 0, 0.4), /* Deeper shadow */
      0 0 30px rgba(255, 127, 50, 0.7), /* Soft orange glow */
      0 0 20px rgba(0, 0, 0, 0.7); /* Darker shadow */
}

.project-image {
  width: 90%; /* Ensure the image takes full width */
  height: 60%; /* Limit the height to 60% of the card */
  object-fit: cover; /* Ensures the image covers the 60% without distortion */
  margin: 0 auto; /* Center the image horizontally */
  border-radius: 40px;
  margin-top: 10px;
  display: block; /* Ensure it's treated as a block element */
}

.project-title {
  flex-grow: 1;
  font-size: 15px;
  padding: 10px;
  color: white;
  text-align: center;
  border-radius: 0 0 10px 10px; /* Rounded bottom corners */
  height: 40%; /* Set the height for the title section */
}

/* Slider navigation buttons */
.slider-button {
  background-color: #FF7F32;
  border: none;
  color: #fff;
  font-size: 2rem;
  padding: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.slider-button.left {
  left: 10px; /* Adjusted for normal view */
}

.slider-button.right {
  right: 10px; /* Adjusted for normal view */
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  /* Adjust project card */
  .project-card {
    flex: 0 0 95%; /* Display only one project at a time */
    max-width: 90%;
    height: 350px; /* Decreased height for mobile view */
  }

  .project-image {
    width: 90%; /* Decreased width for mobile */
    height: 50%; /* Decreased height for mobile */
  }

  .project-title {
    font-size: 11px; /* Smaller font size for mobile */
    height: 40%; /* Adjust title section height */
  }

  .portfolio-projects {
    width: calc(100%); /* Adjust for mobile */
  }

  /* Change section-title color to white in mobile view */
  .section-title {
    color: white;
  }

  /* Adjust category buttons for mobile */
  .category-button {
    padding: 8px 16px;
    font-size: 12px; /* Slightly larger font */
    margin: 5px 10px; /* Increased margin for spacing between buttons on mobile */
    font-weight: bold;
    color: white;
    background-color: #333333;
  }

  .category-slider-button.left,
  .category-slider-button.right {
    font-size: 1.5rem;
    padding: 8px;
  }

  /* Adjust margins to avoid elements touching each other on mobile */
  .category-container {
    gap: 10px; /* Added more gap between buttons */
    margin-top: 10px; /* Ensures top margin is applied */
    margin-bottom: 10px; /* Ensures bottom margin is applied */
  }
}
