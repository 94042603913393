/* General Styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #666666;
  color: #fff;
}

h2.timeline-header {
  text-align: center;
  font-size: 2.5rem;
  margin: 20px 0;
  font-weight: bold;
  text-transform: uppercase;
}

/* Timeline Styles */
.timeline-container {
  position: relative;
  margin: 20px auto;
  padding: 20px;
  max-width: 1200px; /* Increased width */
}

.timeline {
  position: relative;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 4px;
  height: 100%;
  background: orange; /* Line color */
  transform: translateX(-50%);
}

.timeline-item {
  position: relative;
  display: flex;
  margin: 40px 0; /* Increased margin between boxes */
}

.timeline-item:nth-child(odd) {
  justify-content: flex-end;
  text-align: right;
}

.timeline-item:nth-child(even) {
  justify-content: flex-start;
  text-align: left;
}

/* Timeline Card */
.timeline-card {
  background: #333333; /* Card background */
  padding: 20px;
  border-radius: 8px;
  max-width: 500px; /* Increased card width */
  position: relative;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 2;
  transition: transform 0.3s ease; /* Smooth transition for tilt */
}

/* Apply tilt effect on hover */
.timeline-card:hover {
  transform: rotateY(10deg) rotateX(10deg); /* Tilt effect */
}

/* Timeline Icon */
.timeline-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  color: #FF914D;
  width: 40px; /* Increased icon size */
  height: 40px; /* Increased icon size */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 24px;
  font-weight: bold;
  border: 2px solid #FF914D;
  z-index: 3;
}

/* Mobile-specific Icon */
.timeline-item.mobile .timeline-icon {
  position: absolute;
  top: -25px; /* Half inside and half outside */
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
}

.timeline-item.mobile .timeline-card {
  padding-top: 40px; /* Adjust padding for the card in mobile view */
}

/* Title Styles */
.timeline-title {
  font-size: 20px;
  font-weight: bold;
  margin: 0 0 10px;
  color: antiquewhite;
  text-align: center; /* Center title */
}

/* Description Styles */
.timeline-description {
  text-align: center; /* Center description */
  font-size: 10px;
  font-weight: normal;
}

.timeline-description li {
  margin: 10px 0;
  font-size: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .timeline::before {
    display: none; /* Remove line in mobile view */
  }

  .timeline-item {
    flex-direction: column;
    align-items: center; /* Center the items */
    padding-left: 0; /* Remove left padding */
  }

  .timeline-item:nth-child(odd),
  .timeline-item:nth-child(even) {
    justify-content: center; /* Center the content */
    text-align: center; /* Center text */
  }

  .timeline-card {
    margin-left: 0; /* Remove left margin */
    max-width: 80%; /* Increased width for mobile */
  }

  .timeline-icon {
    left: 50%; /* Keep the icon centered */
  }

  .timeline-item {
    margin: 50px 0; /* Increase vertical margin in mobile view */
  }
}

/* Scroll Animations */
.timeline-item {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 1s ease;
}

.timeline-item.in-view {
  opacity: 1;
  transform: translateX(0);
}

.timeline-item:nth-child(odd) {
  transform: translateX(-100px);
}

.timeline-item:nth-child(even) {
  transform: translateX(100px);
}

.timeline-item.in-view:nth-child(odd) {
  transform: translateX(0);
}

.timeline-item.in-view:nth-child(even) {
  transform: translateX(0);
}
