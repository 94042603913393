.tech-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2.5rem; /* Adjust the gap as needed */
  margin: 2rem 0;
}

.tech-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 80%;
  background-color: #333333; /* Background color added */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);  /* Soft shadow effect */
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;  /* Smooth transition for hover effects */
  padding: 1rem;
  border: 5px solid #FF914D;  /* Transparent border for a clean look */
}

.tech-item:hover {
  transform: scale(1.15); /* Zoom-in effect on hover */
  box-shadow: 0 12px 25px rgba(0, 0, 0, 0.2);  /* Larger shadow on hover */
}

.tech-item svg {
  transition: all 0.3s ease;  /* Smooth transition for color changes */
}

.tech-item:hover svg {
  fill: #ffffff;  /* Change icon color to white on hover */
}

@media (max-width: 768px) {
  .tech-item {
    width: 2rem;
    height: 2rem;
  }
}
