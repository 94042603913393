/* General fix for mobile view layout */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  width: 100%; /* Ensure body and html elements don't exceed viewport width */
}

header {
  position: fixed; /* Fixed position */
  top: 0; /* Stay at the top of the page */
  left: 0; /* Ensure it stretches across the full width */
  right: 0; /* Ensure it stretches across the full width */
  z-index: 999; /* Ensures it's above other elements */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px; /* Slightly adjusted padding for a more balanced header */
  background-color: transparent;
  flex-wrap: wrap;
  box-sizing: border-box;
  height: 100px; /* Default height for mobile and smaller views */
}

/* When the menu is open, change the background color */
header.menu-open {
  background-color: #333333; /* Change background color when the menu is open */
}

/* For Desktop View */
@media (min-width: 769px) {
  header {
    height: 140px; /* Increased height by 40px for desktop view */
  }

  header .logo img {
    width: 304px; /* Increased width by 4px for desktop view */
    max-height: 109px; /* Increased height by 4px for desktop view */
  }

  header nav a {
    text-decoration: none;
    color: #FFFFFF; /* White text color by default */
    font-weight: bold;
    font-size: 20px;
    transition: color 0.3s, background-color 0.3s; /* Transition for color and background */
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #FF914D; /* Default background color */
    border: 1px solid #FFFFFF; /* Added white border around links */
  }

  header nav a:hover {
    color: #FFFFFF; /* Text color on hover */
    background-color: #FF6F20; /* Darker background on hover */
  }
}

header .logo {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

header .logo img {
  width: 370px; /* Default logo size */
  height: auto; /* Maintain aspect ratio */
  max-height: 105px; /* Default height for mobile view */
}

header nav {
  display: flex;
  gap: 30px;
  margin-right: 20px;
}

header nav a {
  text-decoration: none;
  color: #FFFFFF; /* Ensure text is white by default */
  font-weight: bold;
  font-size: 20px;
  transition: color 0.3s, background-color 0.3s; /* Transition for color and background */
  padding: 10px 15px;
  border-radius: 15px;
  background-color: transparent; /* Default background color */
  
}

header nav a:hover {
  color: #FFFFFF;
  background-color: #FF6F20; /* Darker orange on hover */
}

/* Hamburger menu */
.hamburger {
  display: none;
  flex-direction: column;
  gap: 5px;
  height: 25px;
  width: 30px;
  cursor: pointer;
  margin-right: 20px;
}

/* Hamburger bars */
.hamburger div {
  height: 4px;
  background-color: #FF914D;
  width: 100%;
  border-radius: 5px;
}

/* Fix for mobile view */
@media (max-width: 768px) {
  header {
    padding: 20px; /* Adjusted padding */
    background-color: transparent; /* Keep background transparent when menu is closed */
  }

  header.menu-open {
    background-color: #333333; /* Change background color when the menu is open */
  }

  header .logo {
    margin-left: 1px; /* Adjusted margin */
  }

  header .logo img {
    width: 170px; /* Slightly smaller logo for mobile */
    max-height: 120px; /* Slightly increased height for mobile logo */
  }

  header nav {
    display: none;
    width: 100%;
    flex-direction: column;
    text-align: center;
    position: absolute;
    top: 70px;
    left: 0;
    background-color: #333333; /* Set background color to #333333 for mobile */
    padding: 20px;
    z-index: 100;
    box-sizing: border-box;
  }

  .hamburger {
    display: flex;
    margin-right: 10px; /* Ensure proper margin on the right */
  }

  header nav a {
    padding: 15px 0; /* Increased padding for better spacing */
    font-size: 18px; /* Slightly smaller font for mobile */
    color: #FF914D;
    text-align: center;
    width: 100%; /* Ensure the links are full width */
    border-bottom: 1px solid #555; /* Optional: Adds a separator between links */
    background-color: transparent; /* Remove background color in mobile view */
  }

  header nav a:hover {
    color: #FFFFFF;
    background-color: #FF6F20; /* Darker orange on hover */
  }

  .nav-links.active {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center the links in the dropdown */
    width: 100%; /* Full width for the dropdown */
  }
}

/* Style the scrollbar */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

/* Style the scrollbar track (the part the thumb slides within) */
::-webkit-scrollbar-track {
  background-color: #333; /* Dark track to match header background */
  border-radius: 10px; /* Rounded corners for the track */
}

/* Style the scrollbar thumb (the part you drag) */
::-webkit-scrollbar-thumb {
  background-color: #FF914D; /* Light orange thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 3px solid #333; /* Adds space around the thumb */
}

/* Style the scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #FF6F20; /* Darker orange thumb on hover */
}

/* Style the scrollbar corner (if present) */
::-webkit-scrollbar-corner {
  background-color: #333;
}
